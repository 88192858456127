import { acceptHMRUpdate, defineStore } from 'pinia'

const MAX_ITEMS_IN_HISTORY = 4

export type RoutingState = Pick<ReturnType<typeof useRoutingStore>, 'history'>

export const useRoutingStore = defineStore('routing', () => {
  const history = ref([] as string[])

  // getters
  const PREVIOUS = computed(() => {
    return history.value[history.value.length - 1] || ''
  })

  // actions
  function SET_PREVIOUS(newRoute: string) {
    history.value = [...history.value, newRoute]
    history.value.length > MAX_ITEMS_IN_HISTORY && history.value.shift()
  }

  return {
    // state
    history,

    // getters
    PREVIOUS,

    // actions
    SET_PREVIOUS,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useRoutingStore, import.meta.hot))
